import { Button, CircularProgress } from "@mui/material";
import { IMXConnectedAccounts } from "../../../hooks/useAccountDetails";

interface IncompleteTorScoreProps {
  connectedAccounts: IMXConnectedAccounts | undefined;
  isDeleteInProgress: boolean;
  handleReLink: () => Promise<void>;
}

const IncompleteTorScore = ({
  connectedAccounts,
  isDeleteInProgress,
  handleReLink,
}: IncompleteTorScoreProps) => {
  return (
    <>
      <h3 style={{ marginBottom: "10px" }}>
        Your Tor Score might be inaccurate or incomplete
      </h3>
      <span style={{ marginBottom: "10px", display: "block", color: "#666" }}>
        Oops, we’ve detected a problem with your linked external accounts and
        are unable to accurately calculate your Tor Score. Please send us a
        message to report this problem. We can get this fixed by removing your
        externally linked accounts and will reply to your message when you are
        able to re-link them. Keep in mind, resolution may take up to 3 business
        days and may temporarily impact your ability to transfer funds
        externally.
      </span>
      <span style={{ marginBottom: "10px", display: "block", color: "#666" }}>
        Status of your connected accounts:
      </span>
      {connectedAccounts?.accounts.map((account, index) => (
        <span
          key={index}
          style={{ marginBottom: "10px", display: "block", color: "#666" }}
        >
          Ending with {account.check_micr} -{" "}
          {account.connection_status === "CONNECTED" ? (
            <span style={{ color: "#339900", fontWeight: "bold" }}>
              CONNECTED
            </span>
          ) : (
            <span style={{ color: "#cc3300", fontWeight: "bold" }}>
              DISCONNECTED
            </span>
          )}
        </span>
      ))}
      <Button
        variant="contained"
        color="primary"
        sx={{
          fontFamily: "Mulish",
          textTransform: "none",
          fontSize: { xs: "16px", sm: "16px" },
          lineHeight: "12px",
          letterSpacing: "0.32px",
          padding: { xs: "18px 24px", sm: "18px 24px" },
          width: "100%",
          marginTop: "10px",
          marginBottom: "10px",
          backgroundColor: "#1881FA",
          fontWeight: "700",
          ":hover": {
            backgroundColor: "#1881FA",
          },
        }}
        onClick={async () => await handleReLink()}
        disabled={isDeleteInProgress}
      >
        {isDeleteInProgress ? (
          <CircularProgress size={16} />
        ) : (
          "Re-Link Accounts"
        )}
      </Button>
    </>
  );
};

export default IncompleteTorScore;
