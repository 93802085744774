import React from "react";
import { Box, Button, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface IncompletedTorScoreModalProps {
  open: boolean;
  handleClose: () => void;
  children: JSX.Element;
}

const IncompletedTorScoreModal: React.FC<IncompletedTorScoreModalProps> = ({
  open,
  handleClose,
  children,
}) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: "464px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div></div>
          <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
        </div>
        {children}
        <Button
          variant="contained"
          color="primary"
          sx={{
            fontFamily: "Mulish",
            textTransform: "none",
            color: "#1881FA",
            border: "1px solid #1881FA",
            fontSize: { xs: "16px", sm: "16px" },
            lineHeight: "12px",
            letterSpacing: "0.32px",
            padding: { xs: "18px 24px", sm: "18px 24px" },
            width: "100%",
            marginTop: "10px",
            marginBottom: "10px",
            backgroundColor: "#FFF",
            fontWeight: "700",
          }}
          onClick={handleClose}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default IncompletedTorScoreModal;
