import { useSelector } from "react-redux";
import { TRootState } from "../..";
import api from "../../api";
import {
  IMXMembersResponse,
  IUserMxAccountsResponse,
  IUserMxTransactionsResponse,
} from "./models";
import { IDeleteResponse } from "../NarmiAPI/models";

const useMXAPI = () => {
  const apiURL = process.env.REACT_APP_SECRETS_API_URL;
  const token = useSelector(
    (state: TRootState) => state.auth.token
  );

  return api.injectEndpoints({
    endpoints: (build) => ({
      getMXMembers: build.query<IMXMembersResponse, void>({
        query: () => {
          return {
            url: `${apiURL}/getMXMembers`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
      }),
      deleteMXMember: build.mutation<IDeleteResponse, { id: string }>({
        query: ({ id }) => ({
          url: `${apiURL}/deleteMXMember/${encodeURIComponent(id)}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
        invalidatesTags: ["MX"]
      }),
      getMXUserAccounts: build.query<IUserMxAccountsResponse, void>({
        query: () => {
          return {
            url: `${apiURL}/getMXAccounts`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
      }),
      getMXUserTransactionsByAccount: build.query<
        IUserMxTransactionsResponse,
        {
          id: string;
          from_date?: string;
          to_date?: string;
          records_per_page?: number;
        }
      >({
        query: ({ id, from_date, to_date, records_per_page }) => {
          const params = new URLSearchParams();
          if (id) params.append("id", id);
          if (from_date) params.append("from_date", from_date);
          if (to_date) params.append("to_date", to_date);
          if (records_per_page)
            params.append("records_per_page", records_per_page.toString());

          return {
            url: `${apiURL}/getMXTransactions/${params.toString() ? `?${params.toString()}` : ""}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
      }),
    }),
  });
};

export default useMXAPI;
