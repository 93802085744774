import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from "../../store";
import { Box, Button,Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { hideAlertAccounts } from "../../store/slices/alertAccountsSlice";

const MAGNOLIA_URL = process.env.REACT_APP_MAGNOLIA_URL || "https://magnolia-liafi.uat.narmitech.com";

const NoAccountsModal: React.FC = () => {
  const dispatch = useDispatch();
  const { open } = useSelector((state: TRootState) => state.alertAccounts);

  if (!open) return null;

  return (
    <Modal
    open={open}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box
      sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        width: "400px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div></div>
        <CloseIcon
          onClick={() => dispatch(hideAlertAccounts())}
          style={{ cursor: "pointer" }}
        />
      </div>
      <h3 style={{ marginBottom: "10px" }}>
       Seems like you did not connect your business account(s)
      </h3>
      <span
        style={{ marginBottom: "20px", display: "block", color: "#666" }}
      >
       In order to provide a 360 view of your finances and an accurate Tor Score, you would need to connect at least one (preferably all) business account(s) you have. The accuracy of your Tor Score is dependent upon the data provided by your financial institution(s) for your linked account(s).
      </span>
      <Button
        variant="contained"
        color="primary"
        sx={{
          fontFamily: "Mulish",
          textTransform: "none",
          fontSize: { xs: "16px", sm: "16px" },
          lineHeight: "12px",
          letterSpacing: "0.32px",
          padding: { xs: "18px 24px", sm: "18px 24px" },
          width: "100%",
          marginBottom: "10px",
          backgroundColor: "#1881FA",
          fontWeight: "700",
          ":hover": {
            backgroundColor: "#1881FA",
          },
        }}
        onClick={() => {
          window.top
            ? (window.top.location.href =
                `${MAGNOLIA_URL}/settings/linked_accounts`)
            : (window.location.href =
                `${MAGNOLIA_URL}/settings/linked_accounts`);
        }}
      >
       Connect my account
      </Button>
      <Button
        variant="outlined"
        color="primary"
        sx={{
          fontFamily: "Mulish",
          textTransform: "none",
          fontSize: { xs: "16px", sm: "16px" },
          lineHeight: "12px",
          letterSpacing: "0.32px",
          padding: { xs: "18px 24px", sm: "18px 24px" },
          width: "100%",
          fontWeight: "700",
          borderColor: "#1881FA",
          color: "#1881FA",
          ":hover": {
            borderColor: "#1881FA",
            backgroundColor: "rgba(24, 129, 250, 0.1)",
          },
        }}
        onClick={() => {
          const targetWindow = window.top ?? window
          targetWindow.location.href = MAGNOLIA_URL
        }}
      >
        I don’t need my Tor Score
      </Button>
    </Box>
  </Modal>
  );
};

export default NoAccountsModal;