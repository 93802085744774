import api from "../../api";
import { IAuthResponse } from "./models";

const apiURL = process.env.REACT_APP_SECRETS_API_URL;

const authAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAuth: build.mutation<IAuthResponse, { code: string }>({
      query: ({ code }) => {
        return {
          url: `${apiURL}/auth/${encodeURIComponent(code)}`,
          method: "GET",
          credentials: "include",
        };
      },
    }),
  }),
});

export default authAPI;
