import { useSelector } from "react-redux";
import { TRootState } from "../..";
import api from "../../api";
import {
  IDeleteUserAccountsResponse,
  IScheduledTransfersResponse,
  IUserAccountsResponse,
  IUserTransactionsResponse,
} from "./models";

const useNarmiAPI = () => {
  const apiURL = process.env.REACT_APP_SECRETS_API_URL;
  const token = useSelector((state: TRootState) => state.auth.token);

  return api.injectEndpoints({
    endpoints: (build) => ({
      getUserAccounts: build.query<IUserAccountsResponse, void>({
        query: () => {
          return {
            url: `${apiURL}/getNarmiAccounts`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            // credentials: "include",
          };
        },
      }),
      deleteUserAccounts: build.mutation<
        IDeleteUserAccountsResponse,
        { ids: string[] }
      >({
        query: ({ ids }) => ({
          url: `${apiURL}/deleteNarmiAccounts`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: {
            ids,
          },
        }),
        invalidatesTags: ["NARMI"],
      }),
      getUserTransactions: build.query<
        IUserTransactionsResponse,
        { records_per_page?: number; settled_from: string; settled_to: string }
      >({
        query: ({ settled_from, settled_to, records_per_page }) => {
          const params = new URLSearchParams();
          if (settled_from) params.append("settled_from", settled_from);
          if (settled_to) params.append("settled_to", settled_to);
          if (records_per_page)
            params.append("per-page", records_per_page.toString());

          const searchParams = params.toString() ? `?${params.toString()}` : "";

          return {
            url: `${apiURL}/getNarmiTransactions${searchParams}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            // credentials: "include",
          };
        },
      }),
      getScheduledTransfers: build.query<IScheduledTransfersResponse, void>({
        query: () => {
          return {
            url: `${apiURL}/getNarmiScheduledTransfers`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
      }),
    }),
  });
};

export default useNarmiAPI;
