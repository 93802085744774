import { Button } from "@mui/material";

interface InstitutionConnectionRemovalProps {
  disconnectedInstitutions: string[];
}

const REDIRECT_URL = `${
  process.env.REACT_APP_MAGNOLIA_URL ||
  "https://magnolia-liafi.uat.narmitech.com"
}/settings/linked_accounts`;

const InstitutionConnectionRemoval = ({
  disconnectedInstitutions,
}: InstitutionConnectionRemovalProps) => {
  const disconnectedInstitutionsString = disconnectedInstitutions.join(", ");
  const plural = disconnectedInstitutions.length > 1

  return (
    <>
      <h3 style={{ marginBottom: "10px" }}>
        {`You successfully removed ${disconnectedInstitutionsString} external connection${plural ? "s" : ""}`}
      </h3>
      <span style={{ marginBottom: "10px", display: "block", color: "#666" }}>
        {`All of accounts associated with ${disconnectedInstitutionsString} are removed from your
        LiaFi account. To re-establish connection${plural ? "s" : ""} with ${disconnectedInstitutionsString} and
        get more accurate Tor Score follow the button below and instantly add
        your accounts again.`}
      </span>
      <Button
        variant="contained"
        color="primary"
        sx={{
          fontFamily: "Mulish",
          textTransform: "none",
          fontSize: { xs: "16px", sm: "16px" },
          lineHeight: "12px",
          letterSpacing: "0.32px",
          padding: { xs: "18px 24px", sm: "18px 24px" },
          width: "100%",
          marginTop: "10px",
          marginBottom: "10px",
          backgroundColor: "#1881FA",
          fontWeight: "700",
          ":hover": {
            backgroundColor: "#1881FA",
          },
        }}
        onClick={() => {
          const targetWindow = window.top ?? window;
          targetWindow.location.href = REDIRECT_URL;
        }}
      >
        Add Accounts Again
      </Button>
    </>
  );
};

export default InstitutionConnectionRemoval;
