import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AlertState {
  open: boolean;
  message: string;
}

const defaultMessage = 'Oops, it seems we’re having some trouble loading your data. Please try refreshing the page. If the issue persists, check back in a few minutes!'

const initialState: AlertState = {
  open: false,
  message: defaultMessage,
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    showAlert: (state, action: PayloadAction<string | undefined>) => {
      state.open = true;
      state.message = action.payload ?? defaultMessage
    },
    hideAlert: (state) => {
      state.open = false;
    },
  },
});

export const { showAlert, hideAlert } = alertSlice.actions;
export default alertSlice.reducer;